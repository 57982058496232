import { Modal, Textarea } from "@equidefi/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { MESSAGE } from "@/constants/forms";

export type TVoidFormValues = {
	reason: string;
};

type TInvestmentVoidModalProps = {
	actionType: "Void" | "Cancel";
	isOpen?: boolean;
	isLoading?: boolean;
	onSubmit?: (data: TVoidFormValues) => void;
	onClose?: () => void;
};

const InvestmentVoidModal: React.FC<TInvestmentVoidModalProps> = ({
	actionType,
	isOpen = false,
	isLoading = false,
	onSubmit = () => {},
	onClose = () => {},
}) => {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {
			reason: "",
		},
		resolver: yupResolver(
			Yup.object().shape({
				reason: Yup.string().required(MESSAGE.REQUIRED("reason")),
			}),
		),
	});

	return (
		<Modal
			title={`${actionType} Agreement`}
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={handleSubmit(onSubmit)}
			size="lg"
			buttons={[
				{
					label: `${actionType} Agreement`,
					size: "sm",
					type: "submit",
					isLoading,
					colorScheme: "red",
				},
				{
					label: "Dismiss",
					size: "sm",
					isDisabled: isLoading,
					action: onClose,
					variant: "outline",
				},
			]}
		>
			<Textarea
				label="Reason"
				helperText={`Provide reason for ${actionType}ing this agreement.`}
				isRequired
				isInvalid={!!errors.reason}
				error={errors.reason?.message}
				rows={5}
				mb="4"
				{...register("reason", { required: true })}
			/>
		</Modal>
	);
};

export default InvestmentVoidModal;

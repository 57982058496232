import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  CardProps,
  Heading,
  HeadingProps,
  Image,
  ImageProps,
} from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import React from "react";
import { Link } from "react-router";

type THeaderCardProps = {
  avatar?: string;
};

type THeaderAvatarProps = {
  url?: string;
  alt?: string;
  boxSize?: ImageProps["boxSize"];
};

export const HeaderAvatar: React.FC<THeaderAvatarProps> = ({
  url,
  alt,
  boxSize = "70px",
}) => {
  return (
    <Box boxSize={boxSize} bg="white" borderRadius="md">
      <Image
        src={url}
        {...(alt ? { alt } : {})}
        boxSize={boxSize}
        objectFit="contain"
      />
    </Box>
  );
};
export const HeaderTitle: React.FC<HeadingProps> = (props) => {
  return <Heading as="h1" fontSize="2xl" fontWeight="bold" {...props} />;
};

type THeaderBreadcrumbProps = {
  crumbs: Record<string, string>;
};
export const HeaderBreadcrumb: React.FC<THeaderBreadcrumbProps> = ({
  crumbs,
}) => {
  return (
    <Breadcrumb
      variant="equidefi"
      spacing="4px"
      color="secondary.500"
      fontWeight="semibold"
      sx={{
        ol: {
          mb: 2,
          pl: 0,
          "li:first-of-type [role=presentation]": { ml: 0 },
        },
      }}
      separator={<Icon.ChevronRight size="1.2em" />}>
      {Object.entries(crumbs).map(([text, to]) => (
        <BreadcrumbItem key={to}>
          <BreadcrumbLink as={Link} to={to}>
            {text}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
      <BreadcrumbItem />
    </Breadcrumb>
  );
};
export const HeaderActions = () => {};
export const HeaderAction = () => {};

export const HeaderCard: React.FC<CardProps> = ({ children, ...rest }) => {
  return (
    <Card {...rest}>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
